import SelectIva from "../../../../components/Preventivo/PreventivoInputs/SelectIva"
import SummaryBox from "../../../../components/Preventivo/SummaryBox/SummaryBox"
import SelectListini from "../../../../components/Preventivo/PreventivoInputs/SelectListini"
import SelectCliente from "../../../../components/Preventivo/PreventivoInputs/SelectCliente"
import SelectModello from "../../../../components/Preventivo/PreventivoInputs/SelectModello"
import SelectVeicolo from "../../../../components/Preventivo/PreventivoInputs/SelectVeicolo"
import VeicoloSummary from "../../../../components/Preventivo/Summaries/VeicoloSummary"
import SelectTemperatura from "../../../../components/Preventivo/PreventivoInputs/SelectTemperatura"
import SelectAlimentazione from "../../../../components/Preventivo/PreventivoInputs/SelectAlimentazione"
import { Switch } from "@mantine/core"
import { Veicolo } from "../../../../queries/veicoli"
import { EEngines } from "../../../../features/settings/settingsSlice"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import {
  selectIsIvaShow,
  setIsIvaShow,
} from "../../../../features/preventivo/preventivoSlice"

interface Step1Props {
  model: string | undefined
  listino: string | undefined
  engine: EEngines | undefined
  veicolo: Veicolo | undefined
}

const Step1 = ({ veicolo }: Step1Props) => {
  const dispatch = useAppDispatch()

  const step1SelectMap = [
    SelectModello,
    SelectListini,
    SelectAlimentazione,
    SelectVeicolo,
  ]

  const isIvaShown = useAppSelector(selectIsIvaShow)
  const handleShowIvaChange = () => {
    dispatch(setIsIvaShow(!isIvaShown ? "true" : "false"))
  }

  return (
    <>
      <div className="mb-10">
        <SummaryBox label="Cliente" withToggle>
          <SelectCliente />
          <SelectTemperatura />
          <div className="mt-5">
            <span className="block mb-1 text-sm font-medium">
              Seleziona le opzioni dell'IVA
            </span>
            <div className="flex items-center gap-6 mb-10">
              <Switch
                size="md"
                onChange={handleShowIvaChange}
                checked={isIvaShown}
              />
              <SelectIva />
            </div>
          </div>
        </SummaryBox>
      </div>
      <div className="mb-10">
        {
          //@ts-ignore
          <VeicoloSummary veicolo={veicolo}>
            <>
              {step1SelectMap.map((Select, index) => (
                <div key={index} className="mb-5">
                  <Select />
                </div>
              ))}
            </>
          </VeicoloSummary>
        }
      </div>
    </>
  )
}

export default Step1
