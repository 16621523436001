import Html from "react-pdf-html";
import PoppinsBold from "../fonts/Poppins-Bold.ttf";
import LogoPreventivo from "../assets/logo_preventivo.png";
import PoppinsRegular from "../fonts/Poppins-Regular.ttf";
import PoppinsSemibold from "../fonts/Poppins-SemiBold.ttf";
import { Option } from "../../../queries/veicoli";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  Image,
  View,
} from "@react-pdf/renderer";
import {
  decodePreventivoString,
  getPrintOptionsHeading,
  transformTimestampToLocaleDateAndTime,
} from "../../../utils/utils";
import { Permuta } from "../../../queries/preventivi";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingHorizontal: 20,
    paddingBottom: 50,
    paddingTop: 20,
  },
  title: {
    fontFamily: "PoppinsRegular",
    fontSize: 9,
    color: "#000",
  },
  content: {
    fontSize: 9,
    textAlign: "center",
    margin: 20,
  },
  logo: {
    height: 64,
    width: "auto",
  },
  image: {
    height: 64,
    width: "auto",
  },
  flexView: {
    flexDirection: "row",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const PreventivoPdfRenderer = ({ preventivoData }: any) => {
  Font.register({
    family: "PoppinsBold",
    fonts: [{ src: PoppinsBold }],
  });

  Font.register({
    family: "PoppinsRegular",
    fonts: [{ src: PoppinsRegular }],
  });

  Font.register({
    family: "PoppinsSemibold",
    fonts: [{ src: PoppinsSemibold }],
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* START: Header */}
        <View
          fixed
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 20,
            marginBottom: 0,
          }}
        >
          <Image style={styles.logo} src={LogoPreventivo} />
          {/* <Image style={styles.image} src={CarImage} /> */}
        </View>
        {/* END: Header */}
        {/* START: Venditore - Spett.le row */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 24,
            marginBottom: 20,
            gap: 8,
          }}
        >
          <View
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Venditore:</Text>
            <Text
              style={{
                ...styles.title,
                fontFamily: "PoppinsSemibold",
                fontWeight: "semibold",
                marginLeft: 4,
              }}
            >
              {preventivoData.agente?.name} -{" "}
            </Text>
            <Text style={{ ...styles.title }}>
              {preventivoData?.agente?.email}
            </Text>
          </View>

          <View
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: 8,
            }}
          >
            <Text
              style={{
                ...styles.title,
                fontFamily: "PoppinsSemibold",
                fontWeight: "semibold",
              }}
            >
              Spett.le:
            </Text>
            <View>
              <Text style={{ ...styles.title }}>
                {preventivoData?.currentCliente?.nome}
              </Text>
              <Text style={{ ...styles.title }}>
                {preventivoData?.currentCliente?.indirizzo}
              </Text>
            </View>
          </View>
        </View>
        {/* END: Venditore - Spett.le row */}
        {/* START: Data - c.a.sig. */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 24,
            marginBottom: 20,
            gap: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
            }}
          >
            <View style={{ width: "50%" }}>
              <Text style={{ ...styles.title }}>
                {transformTimestampToLocaleDateAndTime(
                  preventivoData?.firstSavedAt || 0,
                  false,
                  false,
                )}
              </Text>
            </View>
            <View
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                gap: 8,
              }}
            >
              <Text style={styles.title}>c.a.</Text>
              <Text style={styles.title}>
                {decodePreventivoString(
                  preventivoData?.currentCliente?.cortese_attenzione || "",
                )}
              </Text>
            </View>
          </View>
        </View>
        {/* END: Data - c.a.sig */}
        {/* START: Oggetto */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Text style={styles.title}>Oggetto:</Text>
          <Html style={{ ...styles.title, margin: 0 }}>
            {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.oggetto || "")}
             `}
          </Html>
        </View>
        {/* END: Oggetto */}
        {/* START: Modello */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              ...styles.title,
              marginRight: 2,
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
            }}
          >
            Modello:
          </Text>
          <Html
            style={{
              ...styles.title,
              color: "#334155",
              textTransform: "uppercase",
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
            }}
          >
            {decodePreventivoString(preventivoData?.modello || "")}
          </Html>
        </View>
        {/* END: Modello */}
        {/* START: Dati tecnici */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              ...styles.title,
              marginBottom: 8,
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
            }}
          >
            Dati tecnici:
          </Text>
          {/* START: Motore */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Motore: </Text>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.motore || "")}
             `}
            </Html>
          </View>
          {/* END: Motore */}
          {/* START: Dimensioni */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Dimensioni: </Text>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dimensioni || "")}
             `}
            </Html>
          </View>
          {/* END: Dimensioni */}
          {/* START: Dotazioni */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Dotazioni: </Text>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_guida || "")}
             `}
            </Html>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_sicurezza || "")}
             `}
            </Html>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_interne || "")}
             `}
            </Html>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_tecnologia || "")}
             `}
            </Html>
          </View>
          {/* END: Dotazioni */}
        </View>
        {/* END: Dati tecnici */}
        {/* START: Caratteristiche versione */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Html style={styles.title}>
            {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.caratteristiche_versione || "")}
             `}
          </Html>
        </View>
        {/* END: Caratteristiche versione */}
        {/* START: Optional */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            {getPrintOptionsHeading(
              "Optional",
              preventivoData?.optionalsValues,
              preventivoData?.optionals,
              preventivoData?.printOptions.print_type,
            )}
          </Text>
        </View>
        {/* END: Optional */}
        {/* START: Accessori */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            {getPrintOptionsHeading(
              "Accessori",
              preventivoData?.accessoriValues,
              preventivoData?.accessori,
              preventivoData?.printOptions.print_type,
              true,
            )}
          </Text>
        </View>
        {/* END: Accessori */}
        {/* START: Servizi */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            {getPrintOptionsHeading(
              "Servizi",
              preventivoData?.serviziValues,
              preventivoData?.servizi,
              preventivoData?.printOptions.print_type,
            )}
            {preventivoData?.servizi
              ?.map((servizio: any) => servizio.label)
              .join(" + ")}
          </Text>
        </View>
        {/* END: Servizi */}
        {/* START: Incentivi */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            {"Incentivi:\n"}
            {preventivoData?.incentivi
              .map(
                (opt: Option) =>
                  `${opt.label} (€ ${opt.price.toString().replace("-", "")})`,
              )
              .join(", ")}
          </Text>
        </View>
        {/* END: Incentivi */}

        {/* START: Permute */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>Permute: </Text>
          <Text style={styles.title}>
            {preventivoData.permute
              ?.map(
                (permuta: Permuta) =>
                  `${permuta.title}, targa: ${permuta.targa}, km: ${permuta.km}, anno: ${permuta.year}, iva: ${permuta.iva_type} (€ ${permuta.price.toString().replace("-", "")})`,
              )
              .join(", ")}
          </Text>
        </View>
        {/* END: Permute */}

        {/* START: Modalità di pagamento */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>Modalità di pagamento:</Text>
          <Text style={styles.title}>{preventivoData?.pagamento}</Text>
        </View>
        {/* END: Modalità di pagamento */}

        {/* START: Anticipo */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>Anticipo:</Text>
          <Text style={styles.title}>
            €{" "}
            {preventivoData?.anticipo.toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
        {/* END: Anticipo */}

        {/* START: Data di scadenza preventivo */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>Data di scadenza preventivo:</Text>
          <Text style={styles.title}>
            {transformTimestampToLocaleDateAndTime(
              preventivoData?.data_validita,
              false,
            )}
          </Text>
        </View>
        {/* END: Data di scadenza preventivo */}

        {/* START: Box totale */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "absolute",
            bottom: 20,
            right: 20,
            marginHorizontal: 24,
            borderStyle: "solid",
            borderColor: "#334155",
            borderWidth: 0.5,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                fontSize: 9,
              }}
            >
              Prezzo di listino:{" "}
            </Text>
            <Text
              style={{
                fontSize: 9,
              }}
            >
              €{" "}
              {preventivoData?.listinoPrice.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                fontSize: 9,
              }}
            >
              Sconto totale:{" "}
            </Text>
            <Text
              style={{
                fontSize: 9,
              }}
            >
              €{" "}
              {preventivoData?.scontiTotal.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                fontSize: 9,
              }}
            >
              Permute:{" "}
            </Text>
            <Text
              style={{
                fontSize: 9,
              }}
            >
              €{" "}
              {preventivoData?.permuteTotal.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                fontSize: 9,
              }}
            >
              Tot. su strada escluso IVA:{" "}
            </Text>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "PoppinsSemibold",
                fontWeight: "semibold",
              }}
            >
              €{" "}
              {preventivoData?.priceFinal.toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>
          {preventivoData.showIva && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 4,
              }}
            >
              <Text
                style={{
                  fontSize: 9,
                }}
              >
                IVA:{" "}
              </Text>
              <Text
                style={{
                  fontSize: 9,
                }}
              >
                €{" "}
                {preventivoData?.ivaValue.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Text
              style={{
                fontSize: 9,
              }}
            >
              Tot. su strada IVA inclusa:{" "}
            </Text>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "PoppinsSemibold",
                fontWeight: "semibold",
              }}
            >
              €{" "}
              {(
                preventivoData?.priceFinal +
                preventivoData?.ivaValue -
                preventivoData?.permuteIvaEsente
              ).toLocaleString("it-IT", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        {/* END: Box totale */}
      </Page>
    </Document>
  );
};
export default PreventivoPdfRenderer;
