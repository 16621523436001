import { EScontoType } from "../../../queries/preventivi";
import { NumberInput, TextInput } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useEffect, useState, useCallback } from "react";
import {
  selectAccessoriValues,
  selectPriceFinal,
  selectSconti,
  selectServiziValues,
  selectTotaleVeicolo,
  selectTotalScontiBeforeFinal,
  setSconto,
} from "../../../features/preventivo/preventivoSlice";

interface IDiscountFormProps {
  scontoType: EScontoType;
  isReadOnly: boolean;
}

const DiscountForm = ({ scontoType, isReadOnly }: IDiscountFormProps) => {
  const dispatch = useAppDispatch();
  const [accessoriTotal] = useAppSelector(selectAccessoriValues);
  const [serviziTotal] = useAppSelector(selectServiziValues);
  const [percentage, setPercentage] = useState(0);
  const priceFinal = useAppSelector(selectPriceFinal);
  const veicoloTotal = useAppSelector(selectTotaleVeicolo);
  const totalScontiBeforeFinal = useAppSelector(selectTotalScontiBeforeFinal);
  const sconti = useAppSelector(selectSconti);
  const currentSconto = sconti.find(({ type }) => type === scontoType);

  const calculateDiscountPercentage = (
    currentValue: number,
    type: EScontoType,
  ) => {
    switch (type) {
      case EScontoType.ACCESSORI:
        return (currentValue / accessoriTotal) * 100;
      case EScontoType.SERVIZI:
        return (currentValue / serviziTotal) * 100;
      case EScontoType.VEICOLO:
        return (currentValue / veicoloTotal) * 100;
      case EScontoType.PREZZO_FINALE:
        return ((veicoloTotal - totalScontiBeforeFinal) / priceFinal) * 100;
      default:
        return 0;
    }
  };

  const calculateDiscountPrice = (percentage: number, type: EScontoType) => {
    switch (type) {
      case EScontoType.ACCESSORI:
        return (accessoriTotal * percentage) / 100;
      case EScontoType.SERVIZI:
        return (serviziTotal * percentage) / 100;
      case EScontoType.VEICOLO:
        return (veicoloTotal * percentage) / 100;
      case EScontoType.PREZZO_FINALE:
        return ((veicoloTotal - totalScontiBeforeFinal) * percentage) / 100;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (currentSconto && currentSconto.price > 0) {
      setPercentage(
        calculateDiscountPercentage(currentSconto.price, currentSconto.type),
      );
    }
  }, [currentSconto?.price]);

  const handleChangeDescription = useCallback(
    (value: string) => {
      if (currentSconto) {
        dispatch(setSconto({ ...currentSconto, description: value }));
      }
    },
    [dispatch, scontoType],
  );

  const handleChangePercentage = useCallback(
    (value: string | number) => {
      const currentValue = +value;
      setPercentage(currentValue);
      if (currentSconto) {
        const price = calculateDiscountPrice(currentValue, currentSconto.type);
        dispatch(
          setSconto({ ...currentSconto, price, type: currentSconto.type }),
        );
      }
    },
    [dispatch, scontoType],
  );

  const handleChangeDiscount = useCallback(
    (value: string | number) => {
      const currentValue = +value;
      if (currentSconto) {
        setPercentage(
          calculateDiscountPercentage(currentValue, currentSconto.type),
        );
        dispatch(
          setSconto({
            ...currentSconto,
            price: currentValue,
            type: currentSconto.type,
          }),
        );
      }
    },
    [dispatch, scontoType],
  );

  return (
    <form>
      <div className="mb-5 flex items-center justify-between gap-6">
        <span className="w-60 flex-none text-sm font-medium">
          {currentSconto && currentSconto.label}
        </span>
        <TextInput
          placeholder="Descrizione"
          value={currentSconto?.description ?? ""}
          onChange={(e) => handleChangeDescription(e.target.value)}
          w={"66.67%"}
          radius={12}
          styles={{ input: { height: 44, border: "none" } }}
          className="rounded-xl border"
          disabled={isReadOnly}
        />

        <div className="flex items-center justify-between gap-2">
          <span className="block text-sm font-medium">Importo</span>
          <div className="flex gap-2">
            <NumberInput
              min={0}
              max={100}
              decimalScale={2}
              value={percentage ?? 0}
              onChange={handleChangePercentage}
              allowNegative={false}
              rightSection={<span>%</span>}
              decimalSeparator=","
              radius={12}
              styles={{ input: { height: 44, border: "none" } }}
              className="rounded-xl border"
              disabled={isReadOnly}
            />
            <NumberInput
              min={0}
              value={currentSconto?.price ?? 0}
              onChange={handleChangeDiscount}
              allowNegative={false}
              decimalScale={2}
              rightSection={<span>€</span>}
              decimalSeparator=","
              radius={12}
              styles={{ input: { height: 44, border: "none" } }}
              className="rounded-xl border"
              disabled={isReadOnly}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default DiscountForm;
