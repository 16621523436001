import DetailRow from "./DetailRow/DetailRow";
import { Button } from "@mantine/core";
import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { DetailRowObject } from "./DetailRow/DetailRowTypes";
import { IconChevronDown } from "@tabler/icons-react";
import { changeColorByMarginSign } from "../../../../utils/utils";
import {
  selectAccessoriValues,
  selectOptionalsValues,
  selectMargineVeicolo,
  selectPriceFinal,
  selectServiziValues,
  selectTotalBeforeDiscount,
  selectTotaleVeicolo,
  selectVeicolo,
  selectMargineTotal,
  selectIncentiviValues,
  selectPermuteIvaEsente,
  selectPermuteIvaEsclusa,
  selectIvaValue,
  selectTotLordo,
  selectImponibile,
  selectScontoFinale,
  selectScontoVeicolo,
  selectScontoAccessori,
  selectScontoServizi,
  selectTotEsente,
  selectTotQuotaEsente,
  selectTotDovutoLordo,
} from "../../../../features/preventivo/preventivoSlice";

const TotalDetails = () => {
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const veicolo = useAppSelector(selectVeicolo);
  const totalBeforeDiscount = useAppSelector(selectTotalBeforeDiscount);
  const margineVeicolo = useAppSelector(selectMargineVeicolo);
  const optionalsValues = useAppSelector(selectOptionalsValues);
  const accessoriValues = useAppSelector(selectAccessoriValues);
  const incentiviValues = useAppSelector(selectIncentiviValues);
  const accessoriTotal = useAppSelector(selectAccessoriValues);
  const totaleVeicolo = useAppSelector(selectTotaleVeicolo);
  const serviziTotal = useAppSelector(selectServiziValues);
  const prezzoFinale = useAppSelector(selectPriceFinal);
  const margineTotal = useAppSelector(selectMargineTotal);
  const permuteIvaEsente = useAppSelector(selectPermuteIvaEsente);
  const permuteIvaEsclusa = useAppSelector(selectPermuteIvaEsclusa);
  const totImponibile = useAppSelector(selectImponibile);
  const ivaValue = useAppSelector(selectIvaValue);
  const totLordo = useAppSelector(selectTotLordo);
  const scontiListino = useAppSelector(selectScontoVeicolo);
  const scontiAccessori = useAppSelector(selectScontoAccessori);
  const scontiServizi = useAppSelector(selectScontoServizi);
  const scontoFinale = useAppSelector(selectScontoFinale);
  const totEsente = useAppSelector(selectTotEsente);
  const totQuotaEsente = useAppSelector(selectTotQuotaEsente);
  const totDovutoLordo = useAppSelector(selectTotDovutoLordo);

  const handleClick = () => {
    setShowDetailPanel((prevState) => !prevState);
  };

  const detailRows: Array<DetailRowObject> = Array.from([
    {
      label: "Veicolo Base",
      price: veicolo?.price || 0,
      priceClass: "bg-white",
    },
    {
      label: "Optional Ford",
      price: optionalsValues[0],
      priceClass: "bg-white",
    },
    {
      label: "Totale listino",
      price: totalBeforeDiscount,
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Accessori / Allestimenti after-market",
      price: accessoriTotal[0],
      priceClass: "bg-white",
    },
    {
      label: "Servizi imponibile (messa su strada, gestione pratica, ecc.)",
      price: serviziTotal[0] - totEsente,
      priceClass: "bg-white",
    },
    {
      label: "Servizi quota esente",
      price: totEsente,
      priceClass: "bg-white",
    },
    {
      label: "Totale Veicolo",
      price: totaleVeicolo,
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Sconti su listino",
      price: scontiListino,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass: scontiListino === 0 ? "hidden" : "",
    },
    {
      label: "Sconti su after-market",
      price: scontiAccessori,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass: scontiAccessori === 0 ? "hidden" : "",
    },
    {
      label: "Sconti su servizi",
      price: scontiServizi,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass: scontiServizi === 0 ? "hidden" : "",
    },
    /*
    {
      label: "Sconto quantità",
      price:
        sconti.find(({ type }) => type === EScontoType.ACQUISTI_MULTIPLI)
          ?.price || 0,
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    */
    {
      label: "Sconto Finale",
      price: scontoFinale,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass: scontoFinale === 0 ? "hidden" : "",
    },
    {
      label: "Prezzo su strada",
      price: prezzoFinale,
      priceClass: "text-[1.1rem] bg-slate-600 text-white",
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Permute Iva Esente",
      price: permuteIvaEsente,
      isNegative: true,
      priceClass: `bg-slate-200 ${permuteIvaEsente === 0 ? "hidden" : ""}`,
    },
    {
      label: "Permute Imponibile",
      price: permuteIvaEsclusa,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass: `pb-3 mb-3 border-b ${permuteIvaEsclusa === 0 ? "hidden" : ""}`,
    },
    {
      label: "IMPONIBILE",
      price: totImponibile,
      priceClass: "bg-white",
    },
    {
      label: "TOT. QUOTA ESENTE",
      price: totQuotaEsente,
      priceClass: "bg-white",
    },
    {
      label: "IVA",
      price: ivaValue,
      priceClass: "bg-white",
    },
    {
      label: "TOTALE IVA INCLUSA",
      price: totLordo,
      //rowClass: "mb-10",
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Bonus / Incentivi",
      price: incentiviValues[0],
      isNegative: false,
      priceClass: "bg-slate-200",
      rowClass: -1 * incentiviValues[0] === 0 ? "hidden" : "",
    },
    {
      label: "DOVUTO DA CLIENTE CON IVA",
      price: totDovutoLordo,
      priceClass: "text-[1.1rem] bg-slate-600 text-white",
      rowClass: "pb-3 mb-3 border-b",
    },
  ]);

  const marginRows: Array<DetailRowObject> = Array.from([
    {
      label: "Margine Veicolo+Optional",
      price: margineVeicolo,
      priceClass: changeColorByMarginSign(margineVeicolo),
    },
    {
      label: "Margine Accessori after-martket",
      price: accessoriValues[2],
      priceClass: changeColorByMarginSign(accessoriValues[2]),
    },
    {
      label: "Margine finale (su prezzo m.s.s.)",
      price: margineTotal,
      priceClass: changeColorByMarginSign(margineTotal, true),
    },
  ]);

  return (
    <div className="z-10 rounded-xl border bg-white px-6 py-4 text-slate-600">
      <span className="mb-2 block text-sm font-semibold uppercase">
        Dettagli prezzo
      </span>
      {detailRows.map((detail, index) => (
        <DetailRow
          key={index}
          label={detail.label}
          price={detail.price}
          rowClass={detail.rowClass}
          priceClass={detail.priceClass}
          isNegative={detail.isNegative}
        />
      ))}
      {showDetailPanel &&
        marginRows.map((margin, index) => (
          <DetailRow
            key={`${margin.label}-${index}`}
            label={margin.label}
            price={margin.price}
            priceClass={margin.priceClass}
          />
        ))}
      <div className="flex justify-end">
        <Button
          variant="transparent"
          p={0}
          h={16}
          className="text-[9px] uppercase text-slate-600"
          onClick={handleClick}
          rightSection={
            <IconChevronDown
              className={`h-4 w-4 ${showDetailPanel ? "rotate-180" : ""}`}
            />
          }
        >
          Dettagli
        </Button>
      </div>
    </div>
  );
};

export default TotalDetails;
