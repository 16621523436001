import { createApi, retry } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import settings from "../../settings.json";
import {
  queryAnagrafiche,
  mutationAnagrafica,
  AnagraficaQuery,
  Anagrafica,
  GetAnagraficheResponse,
  deleteAnagrafiche,
} from "../../queries/anagrafica";

import {
  queryVeicoli,
  VeicolisQuery,
  GetVeicolieResponse,
  queryOptionals,
  queryListiniPerModello,
  OptionalsQuery,
  GetOptionalResponse,
  GetListiniResponse,
  ListiniPerModelloQuery,
  GetEnginesResponse,
  EnginesPerModelloListinoQuery,
  queryEnginesPerModelloListino,
  Veicolo,
  mutationVeicoli,
  VeicoloQuery,
  queryVeicolo,
  GetVeicoloResponse,
  Option,
  mutationOption,
  deleteOptions,
} from "../../queries/veicoli";
import {
  GetPreventiviResponse,
  GetPreventivoResponse,
  PreventiviQuery,
  Preventivo,
  PreventivoQuery,
  deletePreventivi,
  mutationPreventivo,
  queryPreventivi,
  queryPreventivo,
} from "../../queries/preventivi";
import {
  NotificationsQuery,
  NotificationsResponse,
  mutationNotification,
  queryNotifications,
  Notification,
} from "../../queries/notification";
import { UsersQuery, UsersResponse, queryUsers } from "../../queries/users";
import { getNewTokenWithRefresh } from "../../app/store";
import {
  deleteTesti,
  GetTestiResponse,
  mutationTesto,
  queryTesti,
  TestiQuery,
  Testo,
} from "../../queries/testi";
import { decodeTesti } from "../../utils/utils";

const { appId, baseUrl } = settings;

export const apiSlice = createApi({
  baseQuery: retry(
    graphqlRequestBaseQuery({
      url: `${baseUrl}/api/client/v2.0/app/${appId}/graphql`,
      prepareHeaders: async (headers, { getState }) => {
        //@ts-ignore
        let { token } = getState().settings;
        const refreshToken = localStorage.getItem("refreshToken") || "";
        const tokenTS = +(localStorage.getItem("tokenTS") || "0");

        const _now = Date.now();
        const _elapsedMinutes = (_now - tokenTS) / 1000 / 60;

        if (_elapsedMinutes > 1) {
          // Refresh token
          token = await getNewTokenWithRefresh(refreshToken);
        }

        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        } else {
          console.log("Token missing back to login...");
          window.location.href = "/login";
        }
        return headers;
      },
      async customErrors(args) {
        console.log(args);
        if (args.message.includes("401")) {
          const refreshToken = localStorage.getItem("refreshToken") || "";
          await getNewTokenWithRefresh(refreshToken);

          //document.location.reload();
        }

        //window.location.href = "/login";
      },
    }),
    {
      maxRetries: 2,
    },
  ),
  tagTypes: [
    "Anagrafica",
    "Veicolo",
    "Optionals",
    "Listini",
    "Preventivi",
    "Engines",
    "Notifications",
    "Testi",
  ],
  endpoints: (builder) => ({
    getListiniForModello: builder.query<
      GetListiniResponse,
      ListiniPerModelloQuery
    >({
      query: queryListiniPerModello,
      transformResponse: (response: { listini_distinct: any }, meta, arg) => {
        // cosi non va bene, le stringhe sono del tipo "18/02/2024%2000:00:00"
        const listini = [...response.listini_distinct.listini].sort().reverse();
        return {
          listini,
        };
      },
      providesTags: ["Listini"],
    }),
    getEnginesPerModelloListino: builder.query<
      GetEnginesResponse,
      EnginesPerModelloListinoQuery
    >({
      query: queryEnginesPerModelloListino,
      transformResponse: (response: { engines_distinct: any }, meta, arg) => {
        return response.engines_distinct;
      },
      providesTags: ["Engines"],
    }),
    getAnagrafiche: builder.query<GetAnagraficheResponse, AnagraficaQuery>({
      query: queryAnagrafiche,
      providesTags: ["Anagrafica"],
    }),
    putAnagrafica: builder.mutation<Anagrafica, Anagrafica>({
      query: mutationAnagrafica,
      invalidatesTags: ["Anagrafica"],
    }),
    deleteAnagrafiche: builder.mutation<null, string[]>({
      query: deleteAnagrafiche,
      invalidatesTags: ["Anagrafica"],
    }),
    getVeicoli: builder.query<GetVeicolieResponse, VeicolisQuery>({
      query: queryVeicoli,
      providesTags: ["Veicolo"],
    }),
    getVeicolo: builder.query<GetVeicoloResponse, VeicoloQuery>({
      query: queryVeicolo,
    }),
    getOptionals: builder.query<GetOptionalResponse, OptionalsQuery>({
      query: queryOptionals,
      providesTags: ["Optionals"],
    }),
    getPreventivi: builder.query<GetPreventiviResponse, PreventiviQuery>({
      query: queryPreventivi,
      providesTags: ["Preventivi"],
    }),
    getPreventivo: builder.query<GetPreventivoResponse, PreventivoQuery>({
      query: queryPreventivo,
    }),
    putPreventivo: builder.mutation<Preventivo, Preventivo>({
      query: mutationPreventivo,
      invalidatesTags: ["Preventivi"],
    }),
    deletePreventivi: builder.mutation<null, string[]>({
      query: deletePreventivi,
      invalidatesTags: ["Preventivi"],
    }),
    deleteTesto: builder.mutation<null, string[]>({
      query: deleteTesti,
      invalidatesTags: ["Testi"],
    }),
    getNotifications: builder.query<NotificationsResponse, NotificationsQuery>({
      query: queryNotifications,
      providesTags: ["Notifications"],
    }),
    putNotification: builder.mutation<Notification, Notification>({
      query: mutationNotification,
      invalidatesTags: ["Notifications"],
    }),
    putTesto: builder.mutation<Testo, Testo>({
      query: mutationTesto,
      invalidatesTags: ["Testi"],
    }),
    getUsers: builder.query<UsersResponse, UsersQuery>({
      query: queryUsers,
    }),
    getTestis: builder.query<GetTestiResponse, TestiQuery>({
      query: queryTesti,
      transformResponse: (response: { testis: [Testo] }) => {
        const transformedResp = response.testis.map((t: Testo) => {
          return {
            ...t,
            testo: t.testo ? decodeTesti(t.testo) : "",
          };
        });

        return { testis: transformedResp };
      },
      providesTags: ["Testi"],
    }),
    putVeicolo: builder.mutation<Veicolo, Veicolo>({
      query: mutationVeicoli,
      invalidatesTags: ["Veicolo"],
    }),
    putOption: builder.mutation<Option, Option>({
      query: mutationOption,
      invalidatesTags: ["Optionals"],
    }),
    deleteOptions: builder.mutation<null, string[]>({
      query: deleteOptions,
      invalidatesTags: ["Optionals"],
    }),
  }),
});

export const {
  useGetAnagraficheQuery,
  usePutAnagraficaMutation,
  useDeleteAnagraficheMutation,
  useDeletePreventiviMutation,
  useGetVeicoliQuery,
  useLazyGetVeicoloQuery,
  useGetVeicoloQuery,
  useGetOptionalsQuery,
  useLazyGetOptionalsQuery,
  useLazyGetListiniForModelloQuery,
  useGetListiniForModelloQuery,
  useGetPreventiviQuery,
  useGetPreventivoQuery,
  useLazyGetPreventivoQuery,
  usePutPreventivoMutation,
  useGetEnginesPerModelloListinoQuery,
  useGetNotificationsQuery,
  usePutNotificationMutation,
  useGetUsersQuery,
  useGetTestisQuery,
  usePutTestoMutation,
  useDeleteTestoMutation,
  usePutVeicoloMutation,
  usePutOptionMutation,
  useDeleteOptionsMutation,
} = apiSlice;
