import usePreventivoData from "../../../../hooks/usePreventivoData"
import { Preventivo } from "../../../../queries/preventivi"
import { notifications } from "@mantine/notifications"
import { IconDeviceFloppy } from "@tabler/icons-react"
import { Button, Group, Radio } from "@mantine/core"
import { useNavigate, useParams } from "react-router-dom"
import { usePutPreventivoMutation } from "../../../../features/api/apiSlice"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { transformTimestampToLocaleDateAndTime } from "../../../../utils/utils"
import PreventivoEditor, {
  IPreventivoEditorProps,
} from "./PreventivoEditor/PreventivoEditor"
import {
  setMotore,
  setModello,
  setOggetto,
  setPrintType,
  selectMotore,
  selectOggetto,
  setDimensioni,
  selectModello,
  selectDimensioni,
  setDotazioniGuida,
  selectPrintOptions,
  setDotazioniInterne,
  setCorteseAttenzione,
  setInitialPreventivo,
  selectDotazioniGuida,
  setDotazioniSicurezza,
  setDotazioniTecnologia,
  selectDotazioniInterne,
  selectCaratteristicheVersione,
  selectCorteseAttenzione,
  selectDotazioniSicurezza,
  selectDotazioniTecnologia,
  setCaratteristicheVersione,
} from "../../../../features/preventivo/preventivoSlice"

const Step4 = () => {
  const dispatch = useAppDispatch()
  const { preventivoId } = useParams()
  const navigate = useNavigate()
  const [putPreventivo, result] = usePutPreventivoMutation()
  const { preventivo } = usePreventivoData({ preventivoId })
  const modello = useAppSelector(selectModello)
  const oggetto = useAppSelector(selectOggetto)
  const motore = useAppSelector(selectMotore)
  const dimensioni = useAppSelector(selectDimensioni)
  const dotazioniGuida = useAppSelector(selectDotazioniGuida)
  const dotazioniSicurezza = useAppSelector(selectDotazioniSicurezza)
  const dotazioniInterne = useAppSelector(selectDotazioniInterne)
  const dotazioniTecnologia = useAppSelector(selectDotazioniTecnologia)
  const caratteristicheVersione = useAppSelector(selectCaratteristicheVersione)
  const corteseAttenzione = useAppSelector(selectCorteseAttenzione)
  const optionsPrintDisplay = useAppSelector(selectPrintOptions)
  // const { data: testi } = useGetTestisQuery({
  //   limit: 10000,
  // })

  const editorMapping: IPreventivoEditorProps[] = [
    {
      field: modello,
      callback: setModello,
      label: "Modello",
      hasToolbar: false,
    },
    { field: oggetto, callback: setOggetto, label: "Oggetto" },
    { field: motore, callback: setMotore, label: "Motore" },
    { field: dimensioni, callback: setDimensioni, label: "Dimensioni" },
    {
      field: dotazioniGuida,
      callback: setDotazioniGuida,
      label: "Dotazioni guida",
    },
    {
      field: dotazioniSicurezza,
      callback: setDotazioniSicurezza,
      label: "Dotazioni sicurezza",
    },
    {
      field: dotazioniInterne,
      callback: setDotazioniInterne,
      label: "Dotazioni interne",
    },
    {
      field: dotazioniTecnologia,
      callback: setDotazioniTecnologia,
      label: "Dotazioni tecnologia",
    },
    {
      field: caratteristicheVersione,
      callback: setCaratteristicheVersione,
      label: "Caratteristiche versione",
    },
  ]
  // const [isSavingEnabled, setIsSavingEnabled] = useState<{
  //   state: boolean;
  //   isLoading: boolean;
  // }>({
  //   state: true,
  //   isLoading: false,
  // });

  const handleSaveCustomPrints = () => {
    const payload: Preventivo = {
      ...preventivo,
    }

    putPreventivo(payload)
      .unwrap()
      .then((response: any) => {
        dispatch(setInitialPreventivo(payload))

        notifications.show({
          withBorder: true,
          withCloseButton: true,
          autoClose: 4000,
          position: "top-center",
          title: "Successo!",
          message: "Dati aggiornati.",
          color: "teal",
          radius: "md",
          loading: false,
        })

        // setIsSavingEnabled({
        //   state: false,
        //   isLoading: false,
        // });

        //@ts-ignore -- non cè modo di fare casting di response
        navigate(`/preventivi/visualizza/${response.upsertOnePreventivi._id}`)
      })
      .catch((error: Error) => {
        console.error("rejected", error)
        notifications.show({
          withCloseButton: true,
          position: "top-center",
          title: "Errore",
          radius: "md",
          message: "Errore nella modifica dei dati. Riprova.",
          color: "pink",
          loading: false,
        })
        // setIsSavingEnabled({
        //   state: true,
        //   isLoading: false,
        // });
      })
  }

  return (
    <>
      <div className="mb-12">
        <div className="mb-8 grid grid-cols-2 gap-3">
          <div>
            <strong>Venditore: </strong>
            <span>
              <strong>{preventivo.agente?.name}</strong> -
              {preventivo.agente?.email}
            </span>
          </div>
          <div>
            <strong>Spett.le: </strong>
            <span>{preventivo.cliente?.nome}</span>
          </div>
        </div>

        <div className="mb-12 grid grid-cols-2 gap-3">
          <div>
            <strong>
              {transformTimestampToLocaleDateAndTime(
                preventivo?.first_saved_at || 0,
                false,
                false,
              )}
            </strong>
          </div>
          <div>
            <strong>c.a. </strong>
            <PreventivoEditor
              field={corteseAttenzione}
              callback={setCorteseAttenzione}
              hasToolbar={false}
            />
          </div>
        </div>

        {editorMapping.map((editor, index) => (
          <div className="mb-8" key={`${index}-${editor.label}`}>
            <strong className="mb-2 block">{editor.label}</strong>
            <PreventivoEditor
              field={editor.field}
              callback={editor.callback}
              hasToolbar={editor.hasToolbar}
            />
          </div>
        ))}
      </div>

      <div className="mb-12">
        <span className="block font-bold">
          Seleziona la preferenza sugli optionals
        </span>
        <Radio.Group
          name="pricePreferences"
          //@ts-ignore
          onChange={(value) => dispatch(setPrintType(value))}
          value={optionsPrintDisplay.print_type}
        >
          <Group mt="md">
            <Radio value="total_price" label="Prezzo cumulativo" />
            <Radio value="single_price" label="Singoli prezzi" />
            <Radio value="no_price" label="Nessun prezzo" />
          </Group>
        </Radio.Group>
      </div>

      <div className="mt-4 flex justify-end">
        <Button
          radius={50}
          size="md"
          rightSection={<IconDeviceFloppy />}
          // leftSection={
          //   isSavingEnabled.isLoading ? (
          //     <Loader color="blue" size="xs" />
          //   ) : (
          //     <IconDeviceFloppy />
          //   )
          // }
          onClick={handleSaveCustomPrints}
        >
          Salva
        </Button>
      </div>
    </>
  )
}

export default Step4
