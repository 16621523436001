import { gql } from "@apollo/client";
import { EEngines } from "../features/settings/settingsSlice";
import { BSON } from "realm-web";

export interface Modello {
  value: string;
  label: string;
}
export interface Veicolo {
  _id?: string;
  id?: string;
  description?: string;
  listino?: string;
  price?: number;
  model: string;
  engine?: EEngines;
  optionals?: Array<string>;
  portata?: string;
  altezza?: string;
  lunghezza?: string;
  cv?: string;
  kw?: string;
  ps?: string;
  mm_vano?: string;
  cambio?: string;
  dimensioni?: string;
  dotazioni_assistenza_alla_guida?: string;
  dotazioni_interne?: string;
  dotazioni_sicurezza?: string;
  dotazioni_tecnologia?: string;
  modello_modelyear?: string;
  modello_tipologia?: string;
  modello_veicolo?: string;
  modello_versione?: string;
  motore?: string;
  pc_tipologia_hl?: string;
  caratteristiche_versione?: string;
  margine_base?: number;
  margine_extra?: number;
  margine_agg?: number;
  margine_fin?: number;
  margine_perm?: number;
}

export enum EOptionType {
  OPTIONALS = "O",
  ACCESSORI = "A",
  SERVIZI = "S",
  INCENTIVI = "I",
  TAX = "T",
}

export interface Option {
  _id?: string;
  id: string;
  label: string;
  listino?: string;
  price: number;
  cm_type: EOptionType;
  obbligatorio?: string;
  veicolo?: string;
  rif_pagamento?: string;
  description?: string;
  fornitore?: string;
  categoria?: string;
  margine_base?: number;
  SoggettoAdIva?: number;
  is_updatable?: boolean;
}

export interface GetVeicoloResponse {
  veicoli: Veicolo;
}

export interface GetVeicolieResponse {
  veicolis: Array<Veicolo>;
}

export interface AnagraficaResponse {
  data: {
    veicoli: Veicolo;
  };
}

export interface VeicolisQuery {
  limit?: number;
  model?: string;
  engine?: string;
  listino?: string;
}
export interface VeicoloQuery {
  id: string;
  listino: string;
}
export interface GetOptionalResponse {
  optionals: Array<Option>;
}

export interface OptionalsQuery {
  limit?: number;
  veicolo?: string;
  listino?: string;
  type?: EOptionType;
  obbligatorio?: string;
}

export interface ListiniPerModelloQuery {
  model?: string;
}

export interface EnginesPerModelloListinoQuery {
  model?: string;
  listino?: string;
}

export interface GetListiniResponse {
  listini: Array<string>;
}

export interface GetEnginesResponse {
  engines: Array<string>;
}

export const queryVeicoli = ({ model, listino, engine }: VeicolisQuery) => ({
  document: gql`
    query {
      veicolis (
        limit: 500, 
        sortBy: _ID_DESC,
        query: {model: "${model}", listino: "${listino}", engine: "${engine}"}
      ) {
        id
        _id
        description
        model
        listino
        engine
        price
        optionals
        portata
        altezza
        lunghezza
        cv
        kw
        ps
        mm_vano
        cambio
        dimensioni
        dotazioni_assistenza_alla_guida
        dotazioni_interne
        dotazioni_sicurezza
        dotazioni_tecnologia
        modello_modelyear
        modello_tipologia
        modello_veicolo
        modello_versione
        motore
        pc_tipologia_hl
        caratteristiche_versione
        margine_base
      }
    }
  `,
});

export const queryVeicolo = ({ id, listino }: VeicoloQuery) => ({
  document: gql`
    query {
      veicoli (query: {id: "${id}", listino: "${listino}"}) {
        id
        _id
        description
        model
        listino
        engine
        price
        optionals
        portata
        altezza
        lunghezza
        cv
        kw
        ps
        mm_vano
        cambio
        dimensioni
        dotazioni_assistenza_alla_guida
        dotazioni_interne
        dotazioni_sicurezza
        dotazioni_tecnologia
        modello_modelyear
        modello_tipologia
        modello_veicolo
        modello_versione
        motore
        pc_tipologia_hl
        caratteristiche_versione
        margine_base
      }
    }
  `,
});

export const queryOptionals = ({
  limit,
  veicolo,
  listino,
  type,
  obbligatorio,
}: OptionalsQuery) => ({
  document: gql`
    query (
      $veicolo: String
      $listino: String
      $type: String
      $obbligatorio: String
    ) {
      optionals(
        limit: ${limit}
        sortBy: LABEL_ASC
        query: {
          veicolo: $veicolo
          listino: $listino
          cm_type: $type
          obbligatorio: $obbligatorio
        }
      ) {
        id
        _id
        label
        cm_type
        price
        listino
        obbligatorio
        veicolo
        margine_base
        description
        fornitore
        categoria
        SoggettoAdIva
        is_updatable
      }
    }
  `,
  variables: {
    veicolo,
    listino,
    type,
    obbligatorio,
  },
});

export const queryListiniPerModello = ({ model }: ListiniPerModelloQuery) => ({
  document: gql`
    query {
      listini_distinct(input: "${model}") {
        listini
      }
    }
  `,
});

export const queryEnginesPerModelloListino = ({
  model,
  listino,
}: EnginesPerModelloListinoQuery) => ({
  document: gql`
    query ($input: EnginesInput) {
      engines_distinct(input: $input) {
        engines
      }
    }
  `,
  variables: {
    input: {
      model,
      listino,
    },
  },
});

export const mutationVeicoli = (veicolo: Veicolo) => ({
  document: gql`
    mutation {
      upsertOneVeicoli(
        data: {
          id: "${veicolo.id}"
          description:  "${veicolo.description}"
          model: "${veicolo.model}"
          listino: "${veicolo.listino}"
          price: ${veicolo.price}
          engine: "${veicolo.engine}"
          optionals: "${veicolo.optionals}"
          portata: "${veicolo.portata || ""}"
          altezza: "${veicolo.altezza || ""}"
          lunghezza: "${veicolo.lunghezza || ""}"
          cv: "${veicolo.cv || ""}"
          kw: "${veicolo.kw || ""}"
          ps: "${veicolo.ps || ""}"
          mm_vano: "${veicolo.mm_vano || ""}"
          modello_modelyear: "${veicolo.modello_modelyear || ""}"
          cambio: "${veicolo.cambio || ""}"
          dimensioni: "${veicolo.dimensioni || ""}"
          dotazioni_assistenza_alla_guida: "${veicolo.dotazioni_assistenza_alla_guida || ""}"
          dotazioni_interne: "${veicolo.dotazioni_interne || ""}"
          dotazioni_sicurezza: "${veicolo.dotazioni_sicurezza || ""}"
          dotazioni_tecnologia: "${veicolo.dotazioni_tecnologia || ""}"
          modello_veicolo: "${veicolo.modello_veicolo || ""}"
          modello_versione: "${veicolo.modello_versione || ""}"
          motore: "${veicolo.motore || ""}"
          pc_tipologia_hl: "${veicolo.pc_tipologia_hl || ""}"
          caratteristiche_versione: "${veicolo.caratteristiche_versione || ""}"
          margine_base: ${veicolo.margine_base || 0}
        }
        query: {
          _id: "${veicolo._id ? new BSON.ObjectID(veicolo._id) : new BSON.ObjectID()}"
        }) {
        _id
      }
    }
  `,
});

export const mutationOption = (option: Option) => ({
  document: gql`
    mutation {
      upsertOneOptional(
        data: {
       id: "${option.id}"
        SoggettoAdIva: ${option.SoggettoAdIva || 1}
        cm_type: "${option.cm_type}"
        label: "${option.label}"
        obbligatorio: "${option.obbligatorio || "false"}"
        price: ${option.price}
        veicolo: "${option.veicolo || ""}"
        margine_base: ${option.margine_base || 0}
        categoria: "${option.categoria || ""}"
        fornitore: "${option.fornitore || ""}"
        description: "${option.description || ""}"
        is_updatable: "${option.is_updatable || "false"}"
        }
        query: {
          _id: "${option._id ? new BSON.ObjectID(option._id) : new BSON.ObjectID()}"
        }) {
        _id
      }
    }
  `,
});

export const deleteOptions = (ids: Array<string>) => ({
  document: gql`
    mutation {
      deleteManyOptionals (
        query: {
          _id_in: [${ids.map((id) => `"${new BSON.ObjectID(id)}"`).join(",")}]
        }
      ) {deletedCount}
    } 
  `,
});
