import { NumberInput, Select } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectVeicolo,
  setVeicolo,
} from "../../../features/preventivo/preventivoSlice";

const MargineForm = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const dispatch = useAppDispatch();
  const veicolo = useAppSelector(selectVeicolo);
  const margine_base = veicolo?.margine_base || 0;
  const margine_extra = veicolo?.margine_extra || 0;
  const margine_agg = veicolo?.margine_agg || 0;
  const margine_fin = veicolo?.margine_fin || 0;
  const margine_perm = veicolo?.margine_perm || 0;

  const margineAggData = ["0", "20", "30", "40", "50"];
  const margineFinData = ["0", "500", "1000", "1500", "2000"];
  const marginePermData = ["0", "500", "1000", "1500", "2000"];
  const margineExtraData = ["0", "500", "1000", "1500", "2000"];

  return (
    <div className="grid grid-cols-2 gap-2">
      <NumberInput
        label="Margine Veicolo+Optional"
        value={margine_base}
        min={0}
        max={100}
        decimalScale={2}
        decimalSeparator=","
        rightSection={<span>%</span>}
        w={"100%"}
        radius={12}
        styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
        disabled
        className="rounded-xl"
      />
      <NumberInput
        label="Margine Veicolo+Optional aggiuntivo"
        value={margine_agg}
        min={0}
        decimalScale={2}
        decimalSeparator=","
        rightSection={<span>%</span>}
        onChange={(e) =>
          veicolo && dispatch(setVeicolo({ ...veicolo, margine_agg: +e }))
        }
        w={"100%"}
        radius={12}
        styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
        className="rounded-xl"
        disabled={isReadOnly}
      />
      <Select
        label="Margine fisso per finanziamento Ford"
        placeholder="Seleziona un margine per finanziamento Ford"
        value={`${margine_fin}`}
        data={margineFinData.map((val) => ({
          value: val,
          label: `${val}€`,
        }))}
        w={"100%"}
        radius={12}
        styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
        className="rounded-xl"
        disabled={isReadOnly}
        onChange={(sel) =>
          veicolo &&
          dispatch(setVeicolo({ ...veicolo, margine_fin: sel ? +sel : 0 }))
        }
      />
      <Select
        label="Margine fisso per premio permuta"
        placeholder="Seleziona un margine per premio permuta"
        value={`${margine_perm}`}
        data={marginePermData.map((val) => ({
          value: val,
          label: `${val}€`,
        }))}
        w={"100%"}
        radius={12}
        styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
        className="rounded-xl"
        disabled={isReadOnly}
        onChange={(sel) =>
          veicolo &&
          dispatch(setVeicolo({ ...veicolo, margine_perm: sel ? +sel : 0 }))
        }
      />
      <NumberInput
        label="Margine extra"
        value={margine_extra}
        min={0}
        decimalScale={2}
        decimalSeparator=","
        rightSection={<span>€</span>}
        onChange={(e) =>
          veicolo && dispatch(setVeicolo({ ...veicolo, margine_extra: +e }))
        }
        w={"100%"}
        radius={12}
        styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
        className="rounded-xl"
        disabled={isReadOnly}
      />
    </div>
  );
};

export default MargineForm;
