import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { EOptionType, Option, Veicolo } from "../../queries/veicoli";
import { Anagrafica } from "../../queries/anagrafica";
import {
  EIvaType,
  EScontoType,
  OPTIONS_PRINT_TYPE,
  PAYMENTS_TYPES,
  Permuta,
  Preventivo,
  Sconto,
} from "../../queries/preventivi";
import { EEngines } from "../settings/settingsSlice";
import { TS4Display } from "../../utils/formatters";

export enum EPreventivoStatus {
  DRAFT = "draft",
  DRAFT_MODIFIED = "draft_modified",
  DRAFT_SAVED = "draft_saved",
  PRINTED = "printed",
  SENT = "sent",
}

export interface PreventivoState extends Preventivo {}

const SCONTI_STUB: Array<Sconto> = [
  {
    label: "Sconto Veicolo+Optionals",
    description: "",
    price: 0,
    type: EScontoType.VEICOLO,
  },
  {
    label: "Sconto su Accessori (after-market)",
    description: "",
    price: 0,
    type: EScontoType.ACCESSORI,
  },
  {
    label: "Sconto su Servizi",
    description: "",
    price: 0,
    type: EScontoType.SERVIZI,
  },
  {
    label: "Sconto su prezzo finale",
    description: "",
    price: 0,
    type: EScontoType.PREZZO_FINALE,
  },
  /*{
    label: "Sconto acquisti multipli",
    description: "",
    price: 0,
    type: EScontoType.ACQUISTI_MULTIPLI,
  },
  */
];

const initialState: PreventivoState = {
  options: [],
  permute: [],
  sconti: SCONTI_STUB,
  anticipo: 0,
  notes: "",
  print_options: {
    show_iva: "false",
    print_type: OPTIONS_PRINT_TYPE.TP,
  },
  status: EPreventivoStatus.DRAFT,
  started_at: Date.now(),
  first_saved_at: 0,
  sconto_temp: {
    label: "Sconto",
    price: 0,
    type: EScontoType.PREZZO_FINALE,
  },
  temperatura: "Tiepida",
  pagamento: "Bonifico Bancario",
  iva: 22,
  data_validita: 0,
};

interface SetOptionAction {
  options: Array<Option>;
  type: EOptionType;
}

export const preventivoSlice = createSlice({
  name: "preventivo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTemperatura: (state, action: PayloadAction<string>) => {
      state.temperatura = action.payload;
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.veicolo = {
        model: action.payload,
        listino: "",
        engine: EEngines._,
      };
      state.options = [];
    },
    setListino: (state, action: PayloadAction<string>) => {
      state.veicolo = {
        //@ts-ignore
        model: state.veicolo.model,
        listino: action.payload || "",
        engine: EEngines._,
      };
      state.options = [];
    },
    setEngine: (state, action: PayloadAction<EEngines>) => {
      state.veicolo = {
        //@ts-ignore
        model: state.veicolo.model,
        //@ts-ignore
        listino: state.veicolo.listino,
        engine: action.payload,
      };
      state.options = [];
    },
    setVeicolo: (state, action: PayloadAction<Veicolo>) => {
      const _idFromPayload = action.payload.id;
      const _idFromPrevState = state.veicolo?.id;

      state.veicolo = {
        ...action.payload,
        engine: action.payload.engine || state.veicolo?.engine,
      };

      if (_idFromPayload !== _idFromPrevState) {
        state.options = [];
      }
    },
    setCliente: (state, action: PayloadAction<Anagrafica>) => {
      state.cliente = action.payload;
    },
    setModello: (state, action: PayloadAction<string>) => {
      state.modello = action.payload;
    },
    setOggetto: (state, action: PayloadAction<string>) => {
      state.oggetto = action.payload;
    },
    setMotore: (state, action: PayloadAction<string>) => {
      state.motore = action.payload;
    },
    setDimensioni: (state, action: PayloadAction<string>) => {
      state.dimensioni = action.payload;
    },
    setIsIvaShow: (state, action: PayloadAction<string>) => {
      state.print_options.show_iva = action.payload;
    },
    setDotazioniGuida: (state, action: PayloadAction<string>) => {
      state.dotazioni_guida = action.payload;
    },
    setDotazioniSicurezza: (state, action: PayloadAction<string>) => {
      state.dotazioni_sicurezza = action.payload;
    },
    setDotazioniInterne: (state, action: PayloadAction<string>) => {
      state.dotazioni_interne = action.payload;
    },
    setDotazioniTecnologia: (state, action: PayloadAction<string>) => {
      state.dotazioni_tecnologia = action.payload;
    },
    setCaratteristicheVersione: (state, action: PayloadAction<string>) => {
      state.caratteristiche_versione = action.payload;
    },
    setCorteseAttenzione: (state, action: PayloadAction<string>) => {
      if (state.cliente) {
        state.cliente.cortese_attenzione = action.payload;
      }
    },
    addPermuta: (state, action: PayloadAction<Permuta>) => {
      state.permute.push({
        ...action.payload,
        price: Math.round(action.payload.price * 100) / 100,
      });
    },
    removePermuta: (state, action: PayloadAction<Permuta>) => {
      const currentPermuteState = state.permute.filter(
        ({ title }) => title !== action.payload.title,
      );
      state.permute = currentPermuteState;
    },
    setOptions: (state, action: PayloadAction<SetOptionAction>) => {
      // Gli obbligatopri non devono mai essere eliminati
      const _optionsToPreserve = state.options.filter(
        ({ cm_type, obbligatorio }) =>
          cm_type !== action.payload.type || "true" === obbligatorio,
      );

      state.options = [
        ..._optionsToPreserve,
        ...action.payload.options.map((opt) => ({
          ...opt,
          price: Math.round(opt.price * 100) / 100,
        })),
      ];
    },
    addOption: (state, action: PayloadAction<Option>) => {
      const hasOption = state.options.find(
        ({ id }) => id === action.payload.id,
      );

      if (!hasOption) {
        state.options.push({
          ...action.payload,
          price: Math.round(action.payload.price * 100) / 100,
        });
      }
    },
    removeOption: (state, action: PayloadAction<Option>) => {
      const hasOption = state.options.find(
        ({ id }) => id === action.payload.id,
      );

      if (hasOption) {
        state.options = state.options.filter(
          ({ id }) => id !== action.payload.id,
        );
      }
    },
    updateOption(state, action: PayloadAction<Option>) {
      const idx = state.options.findIndex(({ id }) => id === action.payload.id);

      if (idx === -1) return;

      state.options[idx] = {
        ...action.payload,
        price: Math.round(action.payload.price * 100) / 100,
      };
    },
    setScontoTemp: (state, action: PayloadAction<Sconto>) => {
      state.sconto_temp = {
        ...action.payload,
        price: Math.round(action.payload.price * 100) / 100,
      };
    },
    setSconto: (state, action: PayloadAction<Sconto>) => {
      const _scontoToSave = {
        ...action.payload,
        price: Math.round(action.payload.price * 100) / 100,
      };

      const idx = state.sconti.findIndex(
        ({ type }) => type === _scontoToSave.type,
      );

      state.sconti[idx] = _scontoToSave;
    },
    setAnticipo: (state, action: PayloadAction<number | string>) => {
      state.anticipo = Math.round(+action.payload * 100) / 100;
    },
    setModalitaPagamento: (state, action: PayloadAction<string>) => {
      const _pagamento = PAYMENTS_TYPES.find(
        ({ label }) => label === action.payload,
      );

      state.sconti = [
        ...state.sconti.filter(
          ({ rif_pagamento }) => !rif_pagamento || rif_pagamento === "",
        ),
      ];
      state.options = [
        ...state.options.filter(
          ({ rif_pagamento }) => !rif_pagamento || rif_pagamento === "",
        ),
      ];

      if (Array.isArray(_pagamento?.sconti) && _pagamento.sconti.length > 0) {
        state.sconti = [...state.sconti, ..._pagamento.sconti];
      }

      if (Array.isArray(_pagamento?.servizi) && _pagamento.servizi.length > 0) {
        _pagamento.servizi.forEach((servizio) => {
          state.options.push(servizio as Option);
        });
      }

      state.pagamento = action.payload;
    },
    setDataValidita: (state, action: PayloadAction<number>) => {
      state.data_validita = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    setIva: (state, action: PayloadAction<number>) => {
      state.iva = action.payload;
    },
    setPrintType: (state, action: PayloadAction<OPTIONS_PRINT_TYPE>) => {
      state.print_options.print_type = action.payload;
    },
    setInitialPreventivo: (state, action: PayloadAction<Preventivo>) => {
      if (
        !Array.isArray(
          // @ts-ignore
          action.payload.sconti || action.payload.sconti.length === 0,
        )
      ) {
        action.payload.sconti = SCONTI_STUB;
      }

      Object.keys(action.payload).forEach((key) => {
        // qui non c'è modo di fare il cast di key a keyof Preventivo
        // quindi andiamo di buon vecchio vanilla js
        const value =
          key.includes("_at") || key.includes("date")
            ? // @ts-ignore
              TS4Display(+action.payload[key])
            : // @ts-ignore
              action.payload[key];

        // @ts-ignore
        state[key] = value;
      });
    },
    reset: () => initialState,
  },
});

export const {
  setTemperatura,
  setInitialPreventivo,
  setVeicolo,
  setListino,
  setEngine,
  addPermuta,
  removePermuta,
  setOptions,
  addOption,
  removeOption,
  updateOption,
  setCliente,
  setModel,
  setModello,
  setOggetto,
  setMotore,
  setDimensioni,
  setDotazioniGuida,
  setDotazioniInterne,
  setDotazioniSicurezza,
  setDotazioniTecnologia,
  setCaratteristicheVersione,
  setCorteseAttenzione,
  setScontoTemp,
  setSconto,
  setAnticipo,
  setModalitaPagamento,
  setNote,
  setIva,
  setIsIvaShow,
  setPrintType,
  setDataValidita,
  reset,
} = preventivoSlice.actions;

export const selectTemperatura = (state: RootState) =>
  state.preventivo.temperatura;
export const selectListino = (state: RootState) =>
  state.preventivo.veicolo?.listino;
export const selectCliente = (state: RootState) => state.preventivo.cliente;
export const selectAgente = (state: RootState) => state.preventivo.agente;
export const selectVeicolo = (state: RootState) => state.preventivo.veicolo;
export const selectEngine = (state: RootState) =>
  state.preventivo.veicolo?.engine;
export const selectModel = (state: RootState) =>
  state.preventivo.veicolo?.model;
export const selectPermute = (state: RootState) => state.preventivo.permute;
export const selectSconti = (state: RootState) => state.preventivo.sconti;
export const selectOptions = (state: RootState) => state.preventivo.options;
export const selectOptionals = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.OPTIONALS);
});
export const selectPrintOptions = (state: RootState) =>
  state.preventivo.print_options;
export const selectAccessori = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.ACCESSORI);
});
export const selectServizi = createSelector([selectOptions], (options) => {
  return options.filter(
    ({ cm_type }) =>
      cm_type === EOptionType.SERVIZI || cm_type === EOptionType.TAX,
  );
});
export const selectTotEsente = createSelector([selectOptions], (options) => {
  return (
    options
      .filter(
        ({ SoggettoAdIva, cm_type }) =>
          SoggettoAdIva === 0 && cm_type !== EOptionType.INCENTIVI,
      )
      .map((opt) => {
        console.log(opt);
        return opt;
      })
      .reduce((acc, { price }) => acc + price, 0) || 0
  );
});
export const selectIncentivi = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.INCENTIVI);
});
export const selectTax = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.TAX);
});
export const selectFirstSavedAt = (state: RootState) =>
  state.preventivo.first_saved_at;
export const selectAnticipo = (state: RootState) => state.preventivo.anticipo;
export const selectDataValidita = (state: RootState) =>
  state.preventivo.data_validita;
export const selectNote = (state: RootState) => state.preventivo.notes;
export const selectIva = (state: RootState) => state.preventivo.iva;
export const selectIsIvaShow = (state: RootState) =>
  state.preventivo.print_options.show_iva === "true" ? true : false;
export const selectModalitaPagamento = (state: RootState) =>
  state.preventivo.pagamento;
export const selectOggetto = (state: RootState) => state.preventivo.oggetto;
export const selectModello = (state: RootState) => state.preventivo.modello;
export const selectMotore = (state: RootState) => state.preventivo.motore;
export const selectDimensioni = (state: RootState) =>
  state.preventivo.dimensioni;
export const selectDotazioniGuida = (state: RootState) =>
  state.preventivo.dotazioni_guida;
export const selectDotazioniSicurezza = (state: RootState) =>
  state.preventivo.dotazioni_sicurezza;
export const selectDotazioniInterne = (state: RootState) =>
  state.preventivo.dotazioni_interne;
export const selectDotazioniTecnologia = (state: RootState) =>
  state.preventivo.dotazioni_tecnologia;
export const selectCaratteristicheVersione = (state: RootState) =>
  state.preventivo.caratteristiche_versione;
export const selectCorteseAttenzione = (state: RootState) =>
  state.preventivo.cliente?.cortese_attenzione;
/*
export const selectFrontDate = (state: RootState) => {
  if (state.preventivo.front_date) {
    return state.preventivo.front_date;
  }
  switch (state.preventivo.status) {
    case EPreventivoStatus.DRAFT:
      // return today as day/month/year string
      const today = new Date();
      return `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    default:
      return "";
  }
};
*/

export const selectScontoFinale = createSelector([selectSconti], (sconti) => {
  return (
    sconti.find(({ type }) => type === EScontoType.PREZZO_FINALE)?.price || 0
  );
});
export const selectScontoServizi = createSelector([selectSconti], (sconti) => {
  return sconti.find(({ type }) => type === EScontoType.SERVIZI)?.price || 0;
});
export const selectScontoAccessori = createSelector(
  [selectSconti],
  (sconti) => {
    return (
      sconti.find(({ type }) => type === EScontoType.ACCESSORI)?.price || 0
    );
  },
);
export const selectScontoVeicolo = createSelector([selectSconti], (sconti) => {
  return sconti.find(({ type }) => type === EScontoType.VEICOLO)?.price || 0;
});

export const selectTotalBeforeDiscount = createSelector(
  [selectOptions, selectVeicolo],
  (options, veicolo) => {
    let total = 0;

    total += veicolo?.price || 0;

    options
      .filter(({ cm_type }) => cm_type === EOptionType.OPTIONALS)
      .forEach((opt) => {
        total += opt.price;
      });

    return total;
  },
);

export const selectAccessoriValues = createSelector(
  [selectAccessori, selectSconti],
  (accessori, sconti) => {
    const accessoriTotalPrice =
      accessori.length > 0
        ? accessori
            .map(({ price }) => price)
            .reduce((acc, price) => acc + price)
        : 0;

    const margineAccessori =
      accessori.length > 0
        ? accessori.reduce(
            (acc, { price, margine_base }) =>
              acc + (price * (margine_base || 0)) / 100,
            0,
          )
        : 0;

    const accessoriTotalDiscount =
      sconti.find(({ type }) => type === EScontoType.ACCESSORI)?.price || 0;

    return [
      accessoriTotalPrice,
      accessoriTotalDiscount,
      margineAccessori - accessoriTotalDiscount,
    ];
  },
);

export const selectPermuteIvaEsclusa = createSelector(
  [selectPermute],
  (permute) => {
    const permuteIvaEsclusa =
      permute
        .filter(({ iva_type }) => iva_type === EIvaType.IVA_ESCLUSA)
        .reduce((acc, { price }) => acc + price, 0) || 0;

    return permuteIvaEsclusa;
  },
);

export const selectPermuteIvaEsente = createSelector(
  [selectPermute],
  (permute) => {
    const permuteIvaEsente =
      permute
        .filter(({ iva_type }) => iva_type === EIvaType.ESENTE_IVA)
        .reduce((acc, { price }) => acc + price, 0) || 0;

    return permuteIvaEsente;
  },
);

export const selectOptionalsValues = createSelector(
  [selectOptionals],
  (optionals) => {
    const optionalsTotalPrice =
      optionals.length > 0
        ? optionals
            .map(({ price }) => price)
            .reduce((acc, price) => acc + price)
        : 0;

    return [optionalsTotalPrice];
  },
);

export const selectServiziValues = createSelector(
  [selectServizi],
  (servizi) => {
    const serviziTotalPrice =
      servizi.length > 0
        ? servizi.map(({ price }) => price).reduce((acc, price) => acc + price)
        : 0;

    return [serviziTotalPrice];
  },
);

export const selectIncentiviValues = createSelector(
  [selectIncentivi],
  (incentivi) => {
    const incentiviTotalPrice =
      incentivi.length > 0
        ? incentivi
            .map(({ price }) => price)
            .reduce((acc, price) => acc + price)
        : 0;

    return [incentiviTotalPrice];
  },
);

export const selectTotalPermute = createSelector([selectPermute], (permute) => {
  return permute.reduce((acc, { price }) => acc + price, 0);
});

export const selectTotaleVeicolo = createSelector(
  [selectTotalBeforeDiscount, selectAccessoriValues, selectServiziValues],
  (totalBeforeDiscount, accessoriValues, serviziValues) => {
    return totalBeforeDiscount + accessoriValues[0] + serviziValues[0];
  },
);

export const selectMargineVeicolo = createSelector(
  [selectTotalBeforeDiscount, selectVeicolo, selectScontoVeicolo],
  (totalBeforeDiscount, veicolo, scontoVeicolo) => {
    const margine_base = veicolo?.margine_base
      ? (totalBeforeDiscount * veicolo.margine_base) / 100
      : 0;
    const margine_agg = veicolo?.margine_agg
      ? (totalBeforeDiscount * veicolo.margine_agg) / 100
      : 0;
    const margine_extra = veicolo?.margine_extra || 0;
    const margine_fin = veicolo?.margine_fin || 0;
    const margine_perm = veicolo?.margine_perm || 0;

    const margine =
      margine_base + margine_extra + margine_agg + margine_fin + margine_perm;

    return margine - scontoVeicolo;
  },
);

export const selectPriceFinal = createSelector(
  [
    selectTotaleVeicolo,
    selectScontoVeicolo,
    selectScontoFinale,
    selectScontoAccessori,
    selectScontoServizi,
  ],
  (
    totaleVeicolo,
    scontoVeicolo,
    scontoFinale,
    scontoAfterMarket,
    scontoServizi,
  ) => {
    return (
      totaleVeicolo -
      scontoFinale -
      scontoVeicolo -
      scontoAfterMarket -
      scontoServizi
    );
  },
);

export const selectImponibile = createSelector(
  [
    selectTotaleVeicolo,
    selectPermuteIvaEsclusa,
    selectScontoVeicolo,
    selectScontoFinale,
    selectScontoAccessori,
    selectScontoServizi,
    selectTotEsente,
  ],
  (
    totaleVeicolo,
    permuteIvaEsclusa,
    scontoVeicolo,
    scontoFinale,
    scontoAfterMarket,
    scontoServizi,
    totEsente,
  ) => {
    return (
      totaleVeicolo -
      scontoFinale -
      scontoVeicolo -
      scontoAfterMarket -
      scontoServizi -
      //scontoQta -
      permuteIvaEsclusa -
      totEsente
    );
  },
);

export const selectMargineTotal = createSelector(
  [
    selectMargineVeicolo,
    selectAccessoriValues,
    selectScontoFinale,
    selectScontoServizi,
  ],
  (margineVeicolo, accessoriValues, scontoFinale, scontoServizi) => {
    /*
    const scontoQta =
      sconti.find(({ type }) => type === EScontoType.ACQUISTI_MULTIPLI)
        ?.price || 0;
    */

    return margineVeicolo + accessoriValues[2] - (scontoFinale + scontoServizi);
  },
);

export const selectScontiTotal = createSelector([selectSconti], (sconti) => {
  const scontoTot = sconti.reduce((acc, { price }) => acc + price, 0) || 0;

  return scontoTot;
});

export const selectTotalScontiBeforeFinal = createSelector(
  [selectScontoVeicolo, selectScontoAccessori, selectScontoServizi],
  (scontoVeicolo, scontoAccessori, scontoServizi) => {
    return scontoVeicolo + scontoAccessori + scontoServizi;
  },
);

export const selectIvaValue = createSelector(
  [selectImponibile, selectIva],
  (imponibile, iva) => {
    const ivaGrossValue = (imponibile * iva) / 100;

    return ivaGrossValue;
  },
);

export const selectTotQuotaEsente = createSelector(
  [selectTotEsente, selectPermuteIvaEsente],
  (totEsente, permuteIvaEsente) => {
    return totEsente - permuteIvaEsente;
  },
);

export const selectTotLordo = createSelector(
  [selectImponibile, selectIvaValue, selectTotQuotaEsente],
  (imponibile, iva, totQuotaEsente) => {
    const lordo = imponibile + iva + totQuotaEsente;

    return lordo;
  },
);

export const selectTotDovutoLordo = createSelector(
  [selectTotLordo, selectIncentiviValues],
  (totLordo, incentiviValues) => {
    const lordo = totLordo + incentiviValues[0];

    return lordo;
  },
);

export default preventivoSlice.reducer;
